<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Mahanagar Palika</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
            <DataTable :value="mahanagarpalikaList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" paginator :totalRecords="totalRecords" :rows="30" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" >
            <template #empty>No Records found.</template>
            <template #loading>Loading data. Please wait...</template>
            <Column field="id" header="Sr." headerStyle="width: 5%" bodyStyle="width: 5%">
                <template #body="{ data }">
                    {{ data.id + 1 }}
                </template>
            </Column>
            <Column field="Name" header="Mahanagar Palika Name" headerStyle="width: 50%" bodyStyle="width: 50%">
                <template #body="{ data }">
                    <div>{{ data.label ? data.label : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="District Name" headerStyle="width: 45%" bodyStyle="width: 45%">
                <template #body="{ data }">
                    <div>{{ data.distlabel ? data.distlabel : "N/A" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
    <script>

export default {
    data() {
        return {
            mahanagarpalikaList: [
                {
                    id: 0,
                    label: "Pune Municipal Corporation",
                    distlabel: "Pune",
                },
                {
                    id: 1,
                    label: "Nagpur Municipal Corporation",
                    distlabel: "Nagpur",
                },
                {
                    id: 2,
                    label: "Thane Municipal Corporation",
                    distlabel: "Thane",
                },
                {
                    id: 3,
                    label: "Pimpri-Chinchwad Municipal Corporation",
                    distlabel: "Pune",
                },
                {
                    id: 4,
                    label: "Nashik Municipal Corporation",
                    distlabel: "Nashik",
                },
                {
                    id: 5,
                    label: "Kalyan-Dombivli Municipal Corporation",
                    distlabel: "Thane",
                },
                {
                    id: 6,
                    label: "Vasai-Virar City Municipal Corporation",
                    distlabel: "Palghar",
                },
                {
                    id: 7,
                    label: "Aurangabad Municipal Corporation",
                    distlabel: "Aurangabad",
                },
                {
                    id: 8,
                    label: "Navi Mumbai Municipal Corporation",
                    distlabel: "Thane",
                },
                {
                    id: 9,
                    label: "Solapur Municipal Corporation",
                    distlabel: "Solapur",
                },
                {
                    id: 10,
                    label: "Meera-Bhyander Municipal Corporation",
                    distlabel: "Thane",
                },
                {
                    id: 11,
                    label: "Bhiwandi-Nizampur Municipal Corporation",
                    distlabel: "Thane",
                },
                {
                    id: 12,
                    label: "Amravati Municipal Corporation",
                    distlabel: "Amravati",
                },
                {
                    id: 13,
                    label: "Nanded-Waghala Municipal Corporation",
                    distlabel: "Nanded",
                },
                {
                    id: 14,
                    label: "Kolhapur Municipal Corporation",
                    distlabel: "Kolhapur",
                },
                {
                    id: 15,
                    label: "Acola Municipal Corporation",
                    distlabel: "Acola",
                },
                {
                    id: 16,
                    label: "Ulhasnagar Municipal Corporation",
                    distlabel: "Thane",
                },
                {
                    id: 17,
                    label: "Sangli, Miraj and Kupwad City Municipal Corporation",
                    distlabel: "sangli",
                },
                {
                    id: 18,
                    label: "Malegaon Municipal Corporation",
                    distlabel: "Nashik",
                },
                {
                    id: 19,
                    label: "Jalgaon Municipal Corporation",
                    distlabel: "Jalgaon",
                },
                {
                    id: 20,
                    label: "Dhule Municipal Corporation",
                    distlabel: "Dhule",
                },
                {
                    id: 21,
                    label: "Ahmednagar Municipal Corporation",
                    distlabel: "Ahmednagar",
                },
                {
                    id: 22,
                    label: "Latur Municipal Corporation",
                    distlabel: "Latur",
                },
                {
                    id: 23,
                    label: "Chandrapur Municipal Corporation",
                    distlabel: "Chandrapur",
                },
                {
                    id: 24,
                    label: "parbhani Municipal Corporation",
                    distlabel: "parbhani",
                },
                {
                    id: 25,
                    label: "Panvel Municipal Corporation",
                    distlabel: "Raigad",
                },
                {
                    id: 26,
                    label: "Ichalkaranji Municipal Corporation",
                    distlabel: "Kolhapur",
                },
                {
                    id: 27,
                    label: "Jalna Municipal Corporation",
                    distlabel: "Jalna",
                },
                {
                    id: 28,
                    label: "Brihanmumbai Municipal Corporation",
                    distlabel: "Mumbai City District , Mumbai Suburban District",
                },
            ],
            totalRecords: 29,
        };
    },
};
</script>